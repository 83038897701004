import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  put({ commit }, { endpoint, payload }) {
    axios
      .put(`snowflakes/${endpoint}`, payload)
      .then(() => {})
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("INCREMENT_RELOAD_KEY")
      })
  },
  async delete({ commit }, { endpoint, payload }) {
    await axios
      .delete(`snowflakes/${endpoint}`, { params: payload })
      .then(() => {})
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("INCREMENT_RELOAD_KEY")
        return Promise.resolve(null)
      })
  },
}

export const mutations = {
  ...BaseMutations,
}
