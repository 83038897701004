import SearchMixin from "@mixins/search"
import { FieldTypes } from "@mixins/fields/types"
import axios from "axios"
import Vue from "vue"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  savedFilterStateChanged: false,
  appliedSavedFilters: {},
  appliedTagFilter: null,
}

export const getters = {
  ...BaseGetters,
  savedFilterStateChanged: (state) => {
    return state.savedFilterStateChanged
  },
  appliedSavedFilters: (state) => {
    return state.appliedSavedFilters
  },
  appliedSavedFilter: (state) => (appContext) => {
    return state.appliedSavedFilters[appContext]
  },
  appliedTagFilter: (state) => {
    return state.appliedTagFilter
  },
}

export const actions = {
  ...BaseActions,
  setSavedFilterStateChanged: ({ commit }, boolean) => {
    commit("SET_SAVED_FILTER_STATE_CHANGED", boolean)
  },
  setAppliedSavedFilter: ({ commit }, payload) => {
    commit("SET_APPLIED_SAVED_FILTER", payload)
  },
  setSavedFilterForSith: ({ commit }, savedFilterForSith) => {
    commit("SET_SAVED_FILTER_FOR_SITH", savedFilterForSith)
  },
  setAppliedTagFilter: ({ commit }, appliedTagFilter) => {
    commit("SET_APPLIED_TAG_FILTER", appliedTagFilter)
  },
  filterVerbChange: ({ commit }, payload) => {
    commit("SET_FILTER_VERBS", payload)
    commit("SET_SAVED_FILTER_STATE_CHANGED", true)
  },
  fieldFilterChange: ({ state, commit, getters, dispatch }, payload) => {
    // Clone the fieldFilterContext
    let fieldFilter = _.cloneDeep(payload.fieldFilter)
    const appContext = payload.appContext
    let appliedSavedFilter = getters["appliedSavedFilter"](appContext)
    let filterData = _.get(appliedSavedFilter, "filter_data", [])
    // Find the index of the fieldFilter by the key (cuid)
    let indices = []
    for (var i = 0; i < filterData.length; i++) {
      if (filterData[i].key === fieldFilter.key) {
        indices.push(i)
      }
    }

    if (indices.length) {
      indices.forEach((index, i) => {
        // If the filter exists
        if (fieldFilter.modOne !== "") {
          commit("UPDATE_FIELD_FILTER", {
            index: index,
            fieldFilter: fieldFilter,
            appContext: appContext,
          })
        } else {
          commit("REMOVE_FIELD_FILTER", {
            appContext: appContext,
            index: index,
          })
        }
      })
    } else {
      if (!_.isEmpty(fieldFilter.modOne)) {
        commit("ADD_FIELD_FILTER", {
          appContext: appContext,
          fieldFilter: fieldFilter,
        })
      }
    }

    // If we are adding or removing a checkbox we need to tell the component that loading has completed so it can re-enable the checkboxes
    if (FieldTypes.CHECKBOX === _.get(fieldFilter, "uc")) {
      let hid = _.get(fieldFilter, "attr", "").split("_")[1]
      if (_.isEmpty(hid)) return
      dispatch(
        "signals/setSignal",
        {
          k: `${hid}CheckboxFilterIsUpdating`,
          v: false,
        },
        { root: true }
      )
    }

    commit("SET_SAVED_FILTER_STATE_CHANGED", true)
  },
  fetchSavedFilters: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("SET_ALL", null)

    const contextObject = {
      indices: ["saved_filters"],
      includes: [
        "label",
        "user_id",
        "group_id",
        "parent_id",
        "filter_data",
        "model_type",
        "app_context",
      ],
    }

    // ===
    // Context filters
    // ===

    if (_.get(payload, "savedFilterContext")) {
      contextObject.filters = [
        {
          attr: "app_context",
          type: "terms",
          verb: "should",
          value: payload.savedFilterContext,
        },
      ]

      // Report-level scope
      if (_.get(payload, "reportId")) {
        contextObject.filters.push({
          attr: "report_id",
          type: "term",
          verb: "must",
          value: payload.reportId,
        })
      }
    }

    // Enforce sorting by number (label_sort_float) and characters (label.raw)
    if (_.isEmpty(contextObject.sorts)) {
      contextObject.sorts = [
        { attr: "label_sort_float", order: "asc" },
        { attr: "label.raw", order: "asc" },
      ]
    } else {
      if (!_.find(contextObject.sorts, { attr: "label.raw" })) {
        contextObject.sorts.unshift({ attr: "label.raw", order: "asc" })
      }
      if (!_.find(contextObject.sorts, { attr: "label_sort_float" })) {
        contextObject.sorts.unshift({ attr: "label_sort_float", order: "asc" })
      }
    }

    contextObject.per_page = _.get(payload, "perPage", 1000)

    return new Promise((resolve, reject) => {
      axios
        .get("search", {
          params: { q: SearchMixin.buildSearchObject(contextObject) },
        })
        .then((res) => {
          commit("SET_ALL", res)
        })
        .catch((e) => {
          console.error(e)
          commit("SET_LOADING", false)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
          resolve()
        })
    })
  },
  fetchSithSaberFilters: ({ commit, getters }, searchObject) => {
    commit("SET_LOADING", true)

    return new Promise((resolve, reject) => {
      axios
        .get("search", { params: { q: searchObject } })
        .then((res) => {
          resolve(res.data.results)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  manageModelSavedFilterRelations: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      if (payload.createRelations.length) {
        axios
          .post("model_saved_filters/batch", {
            model_saved_filters: JSON.stringify(payload.createRelations),
          })
          .then(() => {
            if (payload.saved_filters.length) {
              return axios
                .delete("snowflakes/destroy_model_saved_filters", {
                  params: payload,
                })
                .then(() => {
                  if (payload.parent_attr === "report_id") {
                    dispatch(
                      "reports/customShow",
                      { id: payload.parent_id },
                      { root: true }
                    )
                  }
                  dispatch(
                    "app/setActiveDialog",
                    {
                      dialog: "activeModal",
                    },
                    { root: true }
                  )
                })
            } else if (!payload.bypassReportCleanup) {
              if (payload.parent_attr === "report_id") {
                dispatch(
                  "reports/customShow",
                  { id: payload.parent_id },
                  { root: true }
                )
              }
              dispatch(
                "app/setActiveDialog",
                {
                  dialog: "activeModal",
                },
                { root: true }
              )
            }
          })
          .then(() => {
            if (payload.filterOrder) {
              dispatch(
                "widgetFilterGraphs/update",
                {
                  payload: payload.filterOrder.payload,
                  parentId: payload.filterOrder.parentId,
                },
                { root: true }
              )
            }
          })
          .catch((e) => {
            console.error(e)
            commit("SET_LOADING", false)
            reject(e)
          })
          .finally(() => {
            commit("INCREMENT_RELOAD_KEY")
            commit("SET_LOADING", false)
            resolve()
          })
      } else if (payload.saved_filters.length) {
        if (payload.filterOrder) {
          dispatch(
            "widgetFilterGraphs/update",
            {
              payload: payload.filterOrder.payload,
              parentId: payload.filterOrder.parentId,
            },
            { root: true }
          )
        }
        dispatch("deleteModelSavedFilters", payload)
      } else {
        if (payload.filterOrder) {
          dispatch(
            "widgetFilterGraphs/update",
            {
              payload: payload.filterOrder.payload,
              parentId: payload.filterOrder.parentId,
            },
            { root: true }
          )
        }
        dispatch(
          "app/setActiveDialog",
          {
            dialog: "activeModal",
          },
          { root: true }
        )
      }
    })
  },

  deleteModelSavedFilters: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      axios
        .delete("snowflakes/destroy_model_saved_filters", {
          params: payload,
        })
        .then(() => {
          commit("INCREMENT_RELOAD_KEY")
          if (payload.parent_attr === "report_id") {
            dispatch(
              "reports/customShow",
              { id: payload.parent_id },
              { root: true }
            )
          }

          if (!payload.bypassReportCleanup) {
            dispatch(
              "app/setActiveDialog",
              {
                dialog: "activeModal",
              },
              { root: true }
            )
          }
        })
        .catch((e) => {
          console.error(e)
          commit("SET_LOADING", false)
          reject(e)
        })
        .finally(() => {
          commit("INCREMENT_RELOAD_KEY")
          commit("SET_LOADING", false)
          resolve()
        })
    })
  },

  fetchTotalResults: ({ commit }, payload) => {
    let filtersObj

    if (payload.applied_filters && payload.selected_filter_verb) {
      const filterVerb = payload.selected_filter_verb
      filtersObj = _.each(payload.applied_filters, (f) => {
        f.fv = filterVerb
        if (filterVerb === "AND") {
          if (f.verb === "must_not") {
            f.nestedVerb = "must"
          } else {
            f.verb = "must"
          }
        } else {
          if (f.verb === "must_not") {
            f.nestedVerb = "should"
          } else {
            f.verb = "should"
          }
        }
      })
    }

    const endpoint = _.get(payload, "report_id", null)
      ? "snowflakes/report_actions/all_report_companies"
      : "search"

    const filtersCache = _.compact(payload.filters || filtersObj, [
      {
        attr: "access_policy",
        type: "terms",
        value: SearchMixin.buildAccessPolicyWords({
          groups: [{ id: payload.active_group_id, action: "read" }],
        }),
      },
    ])

    return new Promise((resolve, reject) => {
      axios
        .get(endpoint, {
          params: {
            report_id: _.get(payload, "report_id", null),
            q: SearchMixin.buildSearchObject({
              indices: [`g${payload.active_group_id}_companies`],
              filters: filtersCache,
              per_page: 10000,
              page: 1,
            }),
          },
        })
        .then((resp) => {
          resolve(_.get(resp, "data.meta.total_entries"))
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_SAVED_FILTER_STATE_CHANGED: (state, boolean) => {
    state.savedFilterStateChanged = boolean
  },
  SET_APPLIED_SAVED_FILTER: (state, payload) => {
    let savedFilter = payload.savedFilter
    if (typeof savedFilter.filter_data === "string") {
      savedFilter.filter_data = JSON.parse(savedFilter.filter_data)
    }
    Vue.set(state.appliedSavedFilters, payload.appContext, savedFilter)
  },
  SET_SAVED_FILTER_FOR_SITH: (state, savedFilterForSith) => {
    let appliedSavedFilter =
      state.appliedSavedFilters[savedFilterForSith.appContext]

    if (appliedSavedFilter) {
      Vue.set(appliedSavedFilter, "sith", savedFilterForSith)
    }
  },
  SET_APPLIED_TAG_FILTER: (state, appliedTagFilter) => {
    state.appliedTagFilter = appliedTagFilter
  },
  SET_FILTER_VERBS: (state, payload) => {
    let appliedSavedFilter = state.appliedSavedFilters[payload.appContext]
    appliedSavedFilter.filter_data.map(
      (filter) => (filter.fv = payload.filterVerb)
    )
    Vue.set(appliedSavedFilter, "filter_data", appliedSavedFilter.filter_data)
  },
  ADD_FIELD_FILTER: (state, payload) => {
    // Remove null values from the filter and add it to the array of applied filters
    let appliedSavedFilter = state.appliedSavedFilters[payload.appContext]
    appliedSavedFilter.filter_data.push(_.omitBy(payload.fieldFilter, _.isNil))
    Vue.set(appliedSavedFilter, "filter_data", appliedSavedFilter.filter_data)
  },
  UPDATE_FIELD_FILTER: (state, payload) => {
    // Merge the previous filter with the new context and remove null values
    const appContext = payload.appContext
    let appliedSavedFilter = state.appliedSavedFilters[appContext]
    Vue.set(
      appliedSavedFilter.filter_data,
      payload.index,
      _.omitBy(
        _.assign(
          appliedSavedFilter.filter_data[payload.index],
          payload.fieldFilter
        ),
        _.isNil
      )
    )
  },
  REMOVE_FIELD_FILTER: (state, payload) => {
    const appContext = payload.appContext
    let appliedSavedFilter = state.appliedSavedFilters[appContext]
    // Remove from applied filters if modOne is an empty string
    appliedSavedFilter.filter_data.splice(payload.index, 1)
    Vue.set(appliedSavedFilter, "filter_data", appliedSavedFilter.filter_data)
  },
}
