import Vue from "vue"

export const getters = {
  getSignal: (state) => (signalName) => {
    return _.get(state.cache, signalName)
  },
}

export const actions = {
  setSignal: ({ commit }, signal) => {
    commit("SET_SIGNAL", signal)
  },
}

export const mutations = {
  SET_SIGNAL(state, signal) {
    Vue.set(state.cache, signal.k, signal.v)
  },
}

export const state = {
  cache: {},
}
