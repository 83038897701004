import axios from "axios"
import router from "@router"
import CustomAttrs from "@mixins/custom-attributes"
import { NotificationTypes } from "@mixins/notifications/types"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  notifications: [],
  unreadCount: 0,
}

export const getters = {
  ...BaseGetters,
  unreadCount: (state) => {
    return state.unreadCount
  },
  notifications: (state) => {
    return state.notifications
  },
}

export const actions = {
  ...BaseActions,
  fetchNotificationStats: ({ commit, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get("snowflakes/notification_stats")
        .then((response) => {
          commit("SET_UNREAD_COUNT", response.data.unread)
          commit("SET_LOADING", false)
          resolve(response.data.unread)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  fetchNotifications: ({ commit, dispatch, getters, rootGetters }) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get("notifications", {
          params: {
            q: JSON.stringify({
              custom_attrs: [CustomAttrs.notificationRelationalData()],
              meta: {
                order: "created_at desc",
                per_page: 1000,
                page: 1,
              },
            }),
          },
        })
        .then((response) => {
          const notifications = response.data.notifications
          commit("SET_MODELS", notifications)
          commit("SET_LOADING", false)
          resolve(notifications)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  updateNotification: ({ commit }, notification) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .put(`notifications/${notification.id}`, {
          notification: notification,
        })
        .then((response) => {
          commit("MERGE_UPDATE", response.data.notification)
          commit("SET_LOADING", false)
          resolve(response.data.notification)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  viewNotification: ({ commit, dispatch }, notification) => {
    return new Promise((resolve, reject) => {
      const transitionToReport = (notification, swapGroup) => {
        // Swap Group, if necessary
        if (swapGroup) {
          window.location = `${window.location.protocol}//${window.location.host}/reports/${notification.report_id}?notes=true`
        } else if (
          // Transition, if not already on report show page
          router.currentRoute.name != "report-view" ||
          router.currentRoute.params.id != notification.report_id
        ) {
          dispatch(
            "contextViews/handleReportContextViewTransition",
            {
              parent_type: "Report",
              parent_id: notification.report_id,
              notes: _.includes(
                [NotificationTypes.NEW_COMMENT_ON_REPORT],
                notification.use_case
              ),
            },
            { root: true }
          )
        } else {
          // Open Comment Drawer, if already on report
          dispatch(
            "commentsDrawer/setParams",
            {
              report_id: notification.report_id,
              group_id: notification.group_id,
            },
            { root: true }
          )
        }
      }

      const openCompany = (notification) => {
        dispatch(
          "signals/setSignal",
          {
            k: "companyProfileNotification",
            v: notification,
          },
          { root: true }
        )
        dispatch(
          "companySheet/initAction",
          {
            company: { id: notification.company_id },
          },
          { root: true }
        )
      }

      const openGroup = (notification) => {
        let groupRoute = {
          name: "group-activity",
          params: {
            id: _.get(notification, "word"),
          },
        }
        router
          .push(groupRoute)
          .finally(() => {
            resolve()
          })
          .catch((e) => {})
      }

      const openReport = (notification) => {
        let reportRoute = {
          name: "report-view",
          params: {
            id: _.get(notification, "report_id"),
            permalink: _.get(notification, "context_view_permalink"),
          },
        }
        router
          .push(reportRoute)
          .finally(() => {
            resolve()
          })
          .catch((e) => {})
      }

      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      axios
        .post(`snowflakes/view_notification`, {
          notification_id: notification.id,
        })
        .then((response) => {
          const notification = _.get(response, "data.notification")
          const swapGroup = _.get(response, "data.swapped_group", false)

          if (!swapGroup) {
            dispatch("fetchNotificationStats")
            commit("MERGE_UPDATE", notification)
            commit("SET_LOADING", false)
          }

          if (
            _.includes(
              [
                NotificationTypes.NEW_REPORT_RECEIVED,
                NotificationTypes.NEW_COMMENT_ON_REPORT,
              ],
              notification.use_case
            )
          ) {
            transitionToReport(notification, swapGroup)
          }

          if (
            _.includes(
              [
                NotificationTypes.NEW_DATUM_ON_COMPANY,
                NotificationTypes.NEW_COMMENT_ON_COMPANY,
              ],
              notification.use_case
            )
          ) {
            openCompany(notification)
          }

          if (
            _.includes(
              [NotificationTypes.GROUP_RELATIONSHIP_UPDATE],
              notification.use_case
            )
          ) {
            openGroup(notification)
          }

          if (
            _.includes(
              [NotificationTypes.REPORT_RELATIONSHIP_UPDATE],
              notification.use_case
            )
          ) {
            openReport(notification)
          }

          resolve()
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  deleteAllNotifications: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .delete("snowflakes/delete_all_notifications")
        .then(() => {
          commit("SET_MODELS", [])
          commit("SET_UNREAD_COUNT", null)
          commit("SET_LOADING", false)
          resolve([])
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  markAllNotificationsAsViewed: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .put("snowflakes/mark_all_notifications_as_viewed")
        .then(() => {
          commit("SET_LOADING", false)
          commit("SET_UNREAD_COUNT", null)
          resolve()
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("SET_LOADING", false)
          reject(e)
        })
    })
  },
  removeNotificationCache: ({ commit }, notification) => {
    commit("REMOVE_NOTIFICATION_CACHE", notification)
  },
  incrementUnreadCount: ({ commit }) => {
    commit("INCREMENT_UNREAD_COUNT")
  },
  decrementUnreadCount: ({ commit }) => {
    commit("DECREMENT_UNREAD_COUNT")
  },
  resetNotificationsCache: ({ commit }) => {
    commit("RESET_NOTIFICATIONS_CACHE")
  },
}

export const mutations = {
  ...BaseMutations,
  SET_UNREAD_COUNT(state, count) {
    state.unreadCount = count
  },
  INCREMENT_UNREAD_COUNT(state) {
    state.unreadCount++
  },
  DECREMENT_UNREAD_COUNT(state) {
    state.unreadCount = Math.max(0, state.unreadCount - 1)
  },
  MERGE_UPDATE(state, notification) {
    let cache = _.cloneDeep(state.models)

    cache = _.map(cache, (n) => {
      if (n.id === notification.id) {
        n.viewed = true
      }
      return n
    })

    state.models = cache
  },
  REMOVE_NOTIFICATION_CACHE(state, notification) {
    state.models = state.models.filter((n) => n.id !== notification.id)
    mutations.DECREMENT_UNREAD_COUNT(state)
  },
  RESET_NOTIFICATIONS_CACHE(state) {
    state.models = []
  },
}
