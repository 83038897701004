import axios from "axios"
import Vue from "vue"
// /api/v1/sidekiq_statuses/:id(.:format)

export const state = {
  sidekiqJobStatus: {},
}

export const getters = {
  sidekiqJobStatus(state) {
    return _.get(state, "sidekiqJobStatus", {})
  },
}

export const actions = {
  getSidekiqStatus({ commit }, sidekiqId) {
    return new Promise((resolve, reject) => {
      if (!sidekiqId) {
        commit("SET_SIDEKIQ_JOB_STATUS", null)
        return reject()
      }

      axios
        .get(`/sidekiq_statuses/${sidekiqId}`)
        .then((response) => {
          commit("SET_SIDEKIQ_JOB_STATUS", response.data)
          resolve(response.data)
        })
        .catch((error) => {
          console.error(error)
          commit("SET_SIDEKIQ_JOB_STATUS", null)
          reject()
        })
    })
  },
  clearSidekiqStatus({ commit }) {
    commit("SET_SIDEKIQ_JOB_STATUS", {})
  },
}

export const mutations = {
  SET_SIDEKIQ_JOB_STATUS(state, data) {
    Vue.set(state, "sidekiqJobStatus", data)
  },
}
