import { AppContexts } from "@mixins/app-contexts"

export const state = {
  paginateTable: 0,
  activeTooltip: null,
  loading: false,
  isGridLayout: 1,
  isSwappingLayout: false,
}

export const getters = {
  paginateTable(state) {
    return state.paginateTable
  },
  activeTooltip(state) {
    return state.activeTooltip
  },
  loading(state) {
    return state.loading
  },
  isGridLayout: (state, getters, rootState, rootGetters) => (appContext) => {
    // Grid layout follows user session default behavior (0 = inactive, 1 = active),
    // and falls back to local state if the defaults are not loaded.
    let sessionDefaultBehavior = _.get(
      rootGetters["auth/defaultBehavior"],
      "container_grid"
    )
    let isGridLayout = _.isNumber(sessionDefaultBehavior)
      ? sessionDefaultBehavior
      : state.isGridLayout

    return (
      [AppContexts.REPORTS_REPORTS, AppContexts.COMPANY_REPORTS].includes(
        appContext
      ) && isGridLayout === 1
    )
  },
  isSwappingLayout: (state) => {
    return state.isSwappingLayout
  },
}

export const actions = {
  paginateTable({ commit }) {
    commit("SET_PAGINATE_TABLE")
  },
  setActiveTooltip({ commit }, tooltip) {
    commit("SET_ACTIVE_TOOLTIP", tooltip)
  },
  setLoading({ commit }, isLoading) {
    commit("SET_LOADING", isLoading)
  },
  setGridLayout({ commit }, isGridLayout) {
    // Prevent data source from resetting when grid is swapped.
    commit("SET_IS_SWAPPING_LAYOUT", true)
    commit("SET_GRID_LAYOUT", isGridLayout)
    setTimeout(() => {
      commit("SET_IS_SWAPPING_LAYOUT", false)
    }, 1000)
  },
}

export const mutations = {
  SET_PAGINATE_TABLE(state) {
    state.paginateTable++
  },
  SET_ACTIVE_TOOLTIP(state, tooltip) {
    state.activeTooltip = tooltip
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading
  },
  SET_GRID_LAYOUT(state, isGridLayout) {
    state.isGridLayout = isGridLayout
  },
  SET_IS_SWAPPING_LAYOUT(state, isSwappingLayout) {
    state.isSwappingLayout = isSwappingLayout
  },
}
