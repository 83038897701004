import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import axios from "axios"
import SearchMixin from "@mixins/search"
import ResponseHelpers from "@utils/directives/response-helpers"

export const state = {
  ...BaseState,
  searchObject: null,
  numberOfFilters: 0,
  topBarResults: null,
}

export const getters = {
  ...BaseGetters,
  all: (state) => {
    return state.all
  },
  searchObject: (state) => {
    return state.searchObject
  },
  numberOfFilters: (state) => {
    return state.numberOfFilters
  },
  topBarResults: (state) => {
    return state.topBarResults
      ? ResponseHelpers.parseForAutocomplete(state.topBarResults)
      : state.topBarResults
  },
}

export const actions = {
  ...BaseActions,
  toggleSearch: ({ commit }, namespace) => {
    commit("TOGGLE_STATE", namespace)
  },
  searchObject: ({ commit }, payload) => {
    commit("SET_SEARCH_OBJECT", payload)
  },
  numberOfFilters: ({ commit }, payload) => {
    commit("SET_NUM_OF_FILTERS", payload)
  },
  topBarSearch: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const searchObject = SearchMixin.buildSearchObject(payload)
    axios
      .get("search", { params: { q: searchObject } })
      .then((response) => {
        commit("SET_TOP_SEARCH_RESULTS", response)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  resetTopBarSearch: ({ commit }) => {
    commit("SET_TOP_SEARCH_RESULTS", null)
  },
}

export const mutations = {
  ...BaseMutations,
  SET_SEARCH_OBJECT(state, payload) {
    state.searchObject = payload
  },
  SET_NUM_OF_FILTERS(state, payload) {
    state.numberOfFilters = payload
  },
  SET_TOP_SEARCH_RESULTS(state, payload) {
    state.topBarResults = payload
  },
}
