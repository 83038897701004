import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  fetchReaders: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .get("snowflakes/readers", {
          params: payload,
        })
        .then((resp) => {
          const networkGroups = _.get(resp, "data.networks", [])
          const readers = payload.raw_response
            ? networkGroups
            : buildContextWithHid({
                networks: networkGroups,
                checkPublishability: true,
              })
          resolve(readers)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  index: ({ commit }, qObj) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      axios
        .get("subscriptions", {
          params: { q: JSON.stringify(qObj) },
        })
        .then((resp) => {
          resolve(_.get(resp, "data.subscriptions", []))
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`subscriptions`, payload)
        .then((resp) => {
          resolve(_.get(resp, "data.subscription"))
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`subscriptions/${payload.id}`, payload)
        .then((resp) => {
          resolve(_.get(resp, "data.subscription"))
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
  destroy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`subscriptions/${payload.id}`)
        .then((resp) => {
          resolve(null)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
}

// ---
// Private Helpers
// ---

function buildContextWithHid(data) {
  const skipChecks = !data.checkReadability && !data.checkPublishability
  const context = []

  data.networks.forEach((network) => {
    if (!network) {
      return
    }

    if (data.dividerPresent && context.length) {
      context.push({ divider: true })
    }

    if (
      skipChecks ||
      (data.checkReadability && network.readable) ||
      (data.checkPublishability && network.publishable)
    ) {
      _.set(network, "hid", `Network-${network.id}`)
      context.push(network)
    }

    if (!network.groups) {
      return
    }

    network.groups.forEach((group) => {
      if (
        skipChecks ||
        (data.checkReadability && group.readable) ||
        (data.checkPublishability && group.publishable)
      ) {
        _.set(group, "hid", `Group-${group.id}`)
        _.set(group, "network_name", network.name)
        context.push(group)
      }
    })
  })

  return context
}
