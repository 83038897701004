export const state = {
  back: [],
}

export const getters = {
  latestBack(state) {
    return _.last(state.back)
  },
}

export const actions = {
  pushBack({ commit }, back) {
    commit("PUSH_BACK", back)
  },
  popBack({ commit }) {
    commit("POP_BACK")
  },
  clearBack({ commit }) {
    commit("CLEAR_BACK")
  },
}

export const mutations = {
  PUSH_BACK(state, back) {
    state.back.push(back)
  },
  POP_BACK(state) {
    state.back.pop()
  },
  CLEAR_BACK(state) {
    state.back = []
  },
}
