import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
  networksTableData: (state) => {
    return buildTableData(state)
  },
}

export const actions = {
  ...BaseActions,
  updateNetwork: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    return axios
      .put(`networks/${payload.id}`, payload.params)
      .then((response) => {
        commit("SET_MODEL", response.data["network"])
        dispatch(
          "signals/setSignal",
          {
            k: "updatedNetworkObject",
            v: response.data["network"],
          },
          { root: true }
        )
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
      })
  },
}

export const mutations = {
  ...BaseMutations,
}

// ===
// Private helpers
// ===

function buildTableData(state) {
  const items = []
  const baseObject = {
    selected: [],
    headers: [
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      { text: "Groups", value: "groups_count", align: "left" },
      { text: "Members", value: "members_count", align: "left" },
      {
        text: "Network Administrators",
        value: "network_admins",
        align: "left",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false, align: "left" },
    ],
    items: [],
  }

  if (state.all.length > 1) {
    state.all.forEach((model) => {
      items.push({
        type: model._index,
        id: model.id,
        name: model.name,
        groups_count: model.groups_count || 0,
        members_count: model.members_count || 0,
        logo_url: model.logo_url,
        displayUsers: [],
        displayActions: [
          {
            name: "Disable",
            icon: "domain_disabled",
          },
          {
            name: "Delete",
            icon: "delete",
          },
        ],
      })
    })
  }

  baseObject.items = items
  return baseObject
}
