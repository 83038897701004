import axios from "axios"
import StaticHelper from "@mixins/static-helpers"

import {
  BaseState,
  BaseActions,
  BaseGetters,
  BaseMutations,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  // ---
  // Form Caches
  // ---
  activeCache: null,
  reportFormIndexCache: null,
}

export const getters = {
  ...BaseGetters,
  activeCache: () => {
    return _.get(state, "activeCache")
  },
  reportFormIndexCache: (state) => {
    return state.reportFormIndexCache
  },
}

export const actions = {
  ...BaseActions,
  // Caches
  setActiveCache: ({ commit }, params) => {
    commit("SET_CACHE", {
      cache: "activeCache",
      value: params,
    })
  },
  fetchActiveCacheFields: ({ commit, dispatch }, params) => {
    const actionNamespace = StaticHelper.camelToPascalCase(params.cache)
    const setLoadingState = params.setLoadingState
    if (setLoadingState) {
      commit("SET_CACHE_IS_LOADING", true)
    }
    return new Promise((resolve, reject) => {
      return dispatch("setActiveCache", params).then(() => {
        if (actionNamespace) {
          return dispatch(`fetch${actionNamespace}`, params)
            .then(() => {
              if (setLoadingState) {
                commit("SET_CACHE_IS_LOADING", false)
              }
              resolve()
            })
            .catch((e) => {
              reject(e)
            })
        } else {
          if (setLoadingState) {
            commit("SET_CACHE_IS_LOADING", false)
          }
          resolve()
        }
      })
    })
  },
  fetchReportFormIndexCaches: ({ commit, dispatch, getters }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `cache?id=${params.cache}&cache[report_id]=${params.report_id}&cache[integration_form_id]=${params.integration_form_id}`
        )
        .then((response) => {
          const fields = JSON.parse(response.data.value.fields)
          commit("SET_CACHE", {
            cache: "reportFormIndexCache",
            value: fields,
          })
          resolve()
          return fields
        })
        .catch((error) => {
          console.error(error)
          commit("SET_CACHE", {
            cache: "reportFormIndexCache",
            value: null,
          })
          reject(error)
        })
    })
  },
  putActiveFormCache: ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/cache?id=${params.cache}`, {
          cache: {
            report_id: params.report_id,
            integration_form_id: params.integration_form_id,
            value: JSON.stringify({
              fields: JSON.stringify(params.newCache),
            }),
          },
        })
        .then((response) => {
          const fields = JSON.parse(response.data.value.fields)
          commit("SET_CACHE", {
            cache: "reportFormIndexCache",
            value: fields,
          })
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
  SET_CACHE(state, params) {
    state[params.cache] = params.value
  },
  SET_CACHE_IS_LOADING: (state, newValue) => {
    state.cacheIsLoading = newValue
  },
}
