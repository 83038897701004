import axios from "axios"

export const state = {
  timeoutCache: null,
  timeoutModalActive: false,
}

export const getters = {
  timeoutModalActive(state) {
    return _.get(state, "timeoutModalActive")
  },
}

export const actions = {
  initSessionTimeoutCountdown({ commit }, params) {
    const timeoutLength = _.get(params, "activeGroup.session_timeout")
    const callback = _.get(params, "callback")
    if (timeoutLength && callback) {
      commit("SET_SESSION_TIMEOUT", {
        callback: callback,
        timeoutLength: timeoutLength,
      })
    }
  },
  clearSessionTimeoutCountdown({ commit }) {
    commit("CLEAR_SESSION_TIMEOUT")
    commit("SET_TIMEOUT_MODAL_ACTIVE", false)
  },
  setTimeoutModalActive({ commit }, newState) {
    commit("SET_TIMEOUT_MODAL_ACTIVE", newState)
  },
  pingServer({ commit }, params) {
    const id = _.get(params, "activeUser.id")
    const timeoutLength = _.get(params, "activeGroup.session_timeout")
    if (timeoutLength && id) {
      axios.get(`users/${id}`)
    }
  },
}

export const mutations = {
  SET_SESSION_TIMEOUT(state, params) {
    // The timeout process is adjusted with multiple steps on the front-end to allow for messaging to the user
    // to be presented exactly 1 minute before their server session times out. The algo is as follows:
    // App idles for 5 minutes and then runs this process.
    // A timeout is set for the session timeout length as set on a group minus 6 minutes.
    // 5 minuets of that 6 accounted for minutes has already been spent idle.
    // The extra 1 minute is the alloted time for the countdown popup.
    // timeoutLength is an integer representing a number of minutes. 60000 ms = 1 minute. 360000 ms = 6 minutes.
    const adjustedTimeoutLength = params.timeoutLength * 60000 - 360000
    state.timeoutCache = setTimeout(params.callback, adjustedTimeoutLength)
  },
  CLEAR_SESSION_TIMEOUT(state) {
    clearTimeout(state.timeoutCache)
  },
  SET_TIMEOUT_MODAL_ACTIVE(state, val) {
    state.timeoutModalActive = val
  },
}
