import axios from "axios"
import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  authOnlyConfigUrl: null,
  solutionInstanceCache: null,
  policiesCache: [],
  sidekiqIds: null,

  // Loading
  authLoading: false,
  createTransactionLoading: false,
  recipientRemoveLoading: false,
  refreshSchemaLoading: false,
  updateSolutionInstanceLoading: false,
  importNewCompaniesLoading: false,
}

export const getters = {
  ...BaseGetters,
  authOnlyConfigUrl: (state) => {
    return state.authOnlyConfigUrl
  },
  authLoading: (state) => {
    return state.authLoading
  },
  createTransactionLoading: (state) => {
    return state.createTransactionLoading
  },
  recipientRemoveLoading: (state) => {
    return state.recipientRemoveLoading
  },
  refreshSchemaLoading: (state) => {
    return state.refreshSchemaLoading
  },
  importNewCompaniesLoading: (state) => {
    return state.importNewCompaniesLoading
  },
  updateSolutionInstanceLoading: (state) => {
    return state.updateSolutionInstanceLoading
  },
  solutionInstanceCache: (state) => {
    return state.solutionInstanceCache
  },
  policiesCache: (state) => {
    return state.policiesCache
  },
  sidekiqIds: (state) => {
    return state.sidekiqIds
  },
  sharedGroupIds: (state, getters) => {
    return _.uniq(
      _.compact(
        _.map(getters.policiesCache, (p) => {
          return (
            (p.model_type === "Policy" && p.child_group_id) ||
            (p.model_type === "Group" && p.id)
          )
        })
      )
    )
  },
}

export const actions = {
  ...BaseActions,
  loadSolutionInstance: (
    { commit, getters },
    { solutionInstanceId, setCacheAttrs = [] }
  ) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")
      axios
        .get(`solution_instances/${solutionInstanceId}`, {
          params: {
            q: JSON.stringify({
              include: {
                models: [
                  {
                    lookup_type: "relation",
                    key: "policies",
                    custom_attrs: [{ pattern: "policy_relational_data" }],
                  },
                ],
              },
            }),
          },
        })
        .then((response) => {
          const solutionInstance = response.data.solution_instance
          const cached = _.cloneDeep(getters.solutionInstanceCache)

          // Merge all or specific properties into existing caches to maintain
          // working changes as data initializes or refreshes.

          if (_.isEmpty(cached) || _.isEmpty(setCacheAttrs)) {
            commit("SET_SOLUTION_INSTANCE_CACHE", solutionInstance)
          } else {
            // Set specified model attributes on existing solutionInstanceCache
            _.forEach(setCacheAttrs, (attr) => {
              _.set(cached, attr, _.get(solutionInstance, attr))
            })
            commit("SET_SOLUTION_INSTANCE_CACHE", cached)
          }

          if (
            _.isEmpty(getters.policiesCache) ||
            (!_.isEmpty(setCacheAttrs) && setCacheAttrs.includes("policies"))
          ) {
            commit("SET_POLICIES_CACHE", response.data.policies)
          }

          commit("SET_MODEL", solutionInstance)
          commit("INCREMENT_RELOAD_KEY")
          resolve(solutionInstance)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          commit("RESET_STATE")
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  createSolutionInstanceTransaction: ({ commit }, payload) => {
    const formData = new FormData()
    formData.append("group_id", _.get(payload, "group_id"))
    formData.append(
      "solution_instance[name]",
      _.get(payload, "solution_instance.name")
    )
    formData.append(
      "solution_instance[tray_solution_id]",
      _.get(payload, "solution_instance.tray_solution_id")
    )
    return new Promise((resolve, reject) => {
      commit("SET_CREATE_TRANSACTION_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .post("snowflakes/create_solution_instance_transaction", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const solutionInstance = _.get(response, "data.solution_instance")
          commit("SET_MODEL", solutionInstance)
          commit("SET_SOLUTION_INSTANCE_CACHE", solutionInstance)
          resolve(response.data)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_CREATE_TRANSACTION_LOADING", false)
        })
    })
  },
  setAuthValues: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_AUTH_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .put(
          `snowflakes/set_solution_instance_auth_data?solution_instance_id=${
            payload.id
          }&auth_values=${JSON.stringify(payload.authValues)}`
        )
        .then((response) => {
          const solutionInstance = _.get(response, "data.solution_instance")
          commit("SET_MODEL", solutionInstance)
          commit("SET_SOLUTION_INSTANCE_CACHE", solutionInstance)
          commit("SET_SIDEKIQ_IDS", response.data.meta.sidekiq_ids)
          resolve(response.data.solution_instance)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_AUTH_LOADING", false)
        })
    })
  },
  genAuthOnlyConfigUrl: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_AUTH_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .get(
          `snowflakes/gen_solution_instance_auth_only_config_url?solution_instance_id=${payload.id}`
        )
        .then((response) => {
          commit("SET_AUTH_ONLY_CONFIG_URL", response.data.auth_only_config_url)
          resolve(response.data.auth_only_config_url)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_AUTH_LOADING", false)
        })
    })
  },
  updateSolutionInstanceTransaction: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_UPDATE_SOLUTION_INSTANCE_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .put("snowflakes/update_solution_instance", payload)
        .then((response) => {
          const connection = _.first(_.get(response, "data.connection_object"))
          if (connection) {
            dispatch("connections/pushNewConnection", connection, {
              root: true,
            })
          }
          commit("INCREMENT_RELOAD_KEY")
          resolve(response.data)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_UPDATE_SOLUTION_INSTANCE_LOADING", false)
        })
    })
  },
  trayRecipientRemovePolicy: ({ commit }, payload) => {
    let gid = _.get(payload, "group_id")
    let sid = _.get(payload, "solution_instance_id")
    return new Promise((resolve, reject) => {
      commit("SET_RECIPIENT_REMOVE_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .delete(
          `snowflakes/tray_recipient_remove_policy?group_id=${gid}&solution_instance_id=${sid}`
        )
        .then((response) => {
          commit("INCREMENT_RELOAD_KEY")
          resolve(response.data)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_RECIPIENT_REMOVE_LOADING", false)
        })
    })
  },
  trayRefreshFieldSchema: ({ commit }, payload) => {
    let sid = _.get(payload, "solution_instance_id")
    return new Promise((resolve, reject) => {
      commit("SET_REFRESH_SCHEMA_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .post(
          `snowflakes/tray_refresh_field_schema?solution_instance_id=${sid}`
        )
        .then((response) => {
          commit("SET_SIDEKIQ_IDS", response.data.meta.sidekiq_ids)
          resolve(response.data)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_REFRESH_SCHEMA_LOADING", false)
        })
    })
  },
  trayImportNewCompanies: ({ commit }, payload) => {
    let sid = _.get(payload, "solution_instance_id")
    return new Promise((resolve, reject) => {
      commit("SET_IMPORT_NEW_COMPANIES_LOADING", true)
      commit("CLEAR_ERRORS")
      return axios
        .post(
          `snowflakes/tray_import_new_companies?solution_instance_id=${sid}`
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_IMPORT_NEW_COMPANIES_LOADING", false)
          commit("INCREMENT_RELOAD_KEY")
        })
    })
  },
  initTrayImportNewCompaniesConfirmation: ({ commit, dispatch }, payload) => {
    dispatch(
      "app/setActiveDialog",
      {
        dialog: "activeModal",
        context: "ConfirmationModal",
        title: "Sync Integration",
        meta: {
          model: [payload],
          actionSet: {
            set: "snowflakes",
            action: "tray_import_new_companies",
            submitVerb: "submit",
          },
        },
      },
      { root: true }
    )
  },
  // Cache WIP changes
  setSolutionInstanceCache: ({ commit }, payload) => {
    commit("SET_SOLUTION_INSTANCE_CACHE", payload)
  },
  setSolutionInstanceCacheAttr: ({ commit, dispatch, getters }, payload) => {
    let sic = _.cloneDeep(getters.solutionInstanceCache)
    _.forEach(_.keys(payload), (key) => {
      _.set(sic, key, _.get(payload, key))
    })
    commit("SET_SOLUTION_INSTANCE_CACHE", sic)
  },
  setPoliciesCache: ({ commit }, payload) => {
    commit("SET_POLICIES_CACHE", payload)
  },
  setSidekiqIds: ({ commit }, jobIds) => {
    commit("SET_SIDEKIQ_IDS", jobIds)
  },
  // Transitions
  viewIntegrationCompanies: ({ dispatch }, solutionInstance) => {
    const instanceId = _.get(solutionInstance, "id")
    const prefix = _.get(solutionInstance, "word_prefix")
    dispatch(
      "connections/transitionFromWord",
      { word: `${prefix}${instanceId}` },
      {
        root: true,
      }
    )
  },
  resetState: ({ commit }) => {
    commit("RESET_STATE")
  },
}

export const mutations = {
  ...BaseMutations,
  SET_CONFIG_WIZARD_URL(state, payload) {
    state.configWizardUrl = payload
  },
  SET_AUTH_ONLY_CONFIG_URL(state, payload) {
    state.authOnlyConfigUrl = payload
  },
  SET_AUTH_LOADING(state, payload) {
    state.authLoading = payload
  },
  SET_CREATE_TRANSACTION_LOADING(state, payload) {
    state.createTransactionLoading = payload
  },
  SET_RECIPIENT_REMOVE_LOADING(state, payload) {
    state.recipientRemoveLoading = payload
  },
  SET_REFRESH_SCHEMA_LOADING(state, payload) {
    state.refreshSchemaLoading = payload
  },
  SET_IMPORT_NEW_COMPANIES_LOADING(state, payload) {
    state.importNewCompaniesLoading = payload
  },
  SET_UPDATE_SOLUTION_INSTANCE_LOADING(state, payload) {
    state.updateSolutionInstanceLoading = payload
  },
  SET_SOLUTION_INSTANCE_CACHE(state, payload) {
    state.solutionInstanceCache = payload
  },
  SET_POLICIES_CACHE(state, payload) {
    state.policiesCache = payload
  },
  SET_SIDEKIQ_IDS(state, jobIds) {
    // Supports multiple jobs: schema_import, stats_import
    state.sidekiqIds = jobIds
  },
  RESET_STATE(state) {
    state.authOnlyConfigUrl = null
    state.authLoading = false
    state.createTransactionLoading = false
    state.recipientRemoveLoading = false
    state.refreshSchemaLoading = false
    state.updateSolutionInstanceLoading = false
    state.solutionInstanceCache = null
    state.policiesCache = []
    state.sidekiqIds = null
  },
}
