import axios from "axios"
import router from "@router"
import SearchMixin from "@mixins/search"
import { Providers, getProviderPath } from "@mixins/integrations/providers"
import {
  BaseState,
  BaseActions,
  BaseGetters,
  BaseMutations,
} from "@utils/mixins/store"
import {
  getTypeformResponses,
  setTypeformResponses,
  setEditedTypeformResponse,
  getTypeformFileNameFromUrl,
} from "@mixins/integrations/typeform"

export const state = {
  ...BaseState,
  integrations: null,
  importedForms: null,

  // ---
  // Provider Connections
  // ---

  typeformIntegration: null,
  typeformForms: [],
  integrationForms: [],
  loadingTypeformForms: false,
  selectedTypeform: null,

  // --
  // Form Integration
  // ---

  formResponses: [],
  rawFormResponses: [],
  activeForm: {},
  editedForm: {},
  activeFormExternalPath: null,
  activeFormReload: 0,
  canEditFormResponses: false,
}

export const getters = {
  ...BaseGetters,
  getErrorMessage: function (state) {
    return state.errors
  },

  // ---
  // Provider Connections
  // ---

  typeform: (state) => {
    let typeForm
    if (Array.isArray(state.integrations)) {
      for (const x in state.integrations) {
        typeForm = state.integrations[x]
      }
    } else {
      typeForm = state.integrations
    }
    return typeForm
  },
  typeformForms: () => {
    return _.get(state, "typeformForms")
  },
  filteredFypeformForms: () => {
    let forms = _.get(state, "typeformForms")
    forms = _.compact(
      forms.map((obj) => {
        if (_.get(obj, "settings.is_public")) {
          return obj
        }
      })
    )
    return forms
  },
  loadingTypeformForms: () => {
    return _.get(state, "loadingTypeformForms")
  },

  // --
  // Form Integration
  // ---

  typeformIntegration: () => {
    return _.get(state, "typeformIntegration")
  },
  formResponses: () => {
    return _.get(state, "formResponses")
  },
  rawFormResponses: () => {
    return _.get(state, "rawFormResponses")
  },
  activeForm: () => {
    return _.get(state, "activeForm")
  },
  activeFormFields: () => {
    return _.get(state, "activeForm.fields")
  },
  editedForm: () => {
    return _.get(state, "editedForm")
  },
  activeFormReload: () => {
    return _.get(state, "activeFormReload")
  },
  activeFormExternalPath: () => {
    return _.get(state, "activeFormExternalPath")
  },
  canEditFormResponses: () => {
    return _.get(state, "canEditFormResponses")
  },
  selectedTypeform: () => {
    return _.get(state, "selectedTypeform")
  },

  // Form Table
  integrationForms: () => {
    return state.integrationForms
  },
  importedForms: () => {
    return state.importedForms
  },
}

export const actions = {
  ...BaseActions,

  // ---
  // Provider Connections
  // ---

  createIntegration: ({ commit }, payload) => {
    return axios
      .post("/integrations", payload)
      .then((resp) => {
        let integration = resp.data.integration
        commit("CLEAR_ERRORS")
        commit("SET_INTEGRATION", integration)
        commit("SET_TYPEFORM_INTEGRATION", integration)
        return integration
      })
      .catch((e) => {
        commit("SET_ERRORS_INTEGRATION", e.response.data.errors[0].detail)
        return e.response.data.errors[0].status
      })
  },
  updateIntegration: ({ commit }, payload) => {
    return axios
      .put(`/integrations/${payload.id}`, payload)
      .then((resp) => {
        commit("CLEAR_ERRORS")
        commit("SET_INTEGRATION", resp.data.integration)
        return resp.data.integration
      })
      .catch((e) => {
        console.error(e)
        commit("SET_ERRORS_INTEGRATION", e.response.data.errors[0].detail)
        return e.response.data.errors[0].status
      })
  },
  loadTypeformIntegration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("integrations/find", {
          params: {
            q: JSON.stringify({
              find_by: {
                group_id: payload.group_id,
                use_case: Providers.TYPEFORM,
              },
            }),
          },
        })
        .then((res) => {
          let typeformIntegration = res.data.integration
          commit("SET_TYPEFORM_INTEGRATION", typeformIntegration)
          resolve(typeformIntegration)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  loadPitchbookIntegration({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("integrations/find", {
          params: {
            q: JSON.stringify({
              find_by: {
                group_id: payload.group_id,
                use_case: Providers.PITCHBOOK,
              },
            }),
          },
        })
        .then((res) => {
          resolve(res.data.integration)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  loadIntegration({ commit }, payload) {
    const searchObject = SearchMixin.buildSearchObject(payload)
    return axios
      .get("search", { params: { q: searchObject } })
      .then((resp) => {
        if (!resp.data.integrations) {
          commit("CLEAR_ERRORS")
          commit("SET_INTEGRATION", null)
        }
        if (resp.data.integrations) {
          commit("CLEAR_ERRORS")
          commit("SET_INTEGRATION", resp.data.integrations)
        }
        return resp.data
      })
      .catch((e) => {
        console.error(e)
      })
  },
  deleteIntegration({ commit }, payload) {
    return axios
      .delete(`/integrations/${payload.id}`, payload)
      .then((resp) => {
        commit("SET_INTEGRATION", resp.data)
        return resp.data
      })
      .catch((e) => {
        console.error(e)
        return e.response.data.errors[0].status
      })
  },

  // --
  // Form Integration
  // ---

  setActiveForm({ commit }, form) {
    commit("SET_ACTIVE_FORM", form)
    commit("SET_EDITED_FORM", _.cloneDeep(form))
    commit("INCREMENT_ACTIVE_FORM_RELOAD")
  },
  setEditedForm({ commit }, form) {
    commit("SET_EDITED_FORM", form)
  },
  loadFormResponses({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      return axios
        .get("snowflakes/form_responses", {
          params: {
            report_id: payload.report_id,
            integration_form_id: payload.integration_form_id,
            company_id: payload.company_id,
          },
        })
        .then((resp) => {
          const iForm = _.get(resp, "data.integration_forms[0]", null)
          const parsedResponses = getResponses(resp.data)
          commit("SET_ACTIVE_FORM_EXTERNAL_PATH", _.get(iForm, "form"))
          commit("SET_RAW_FORM_RESPONSES", _.get(iForm, "form_responses", []))
          commit("SET_FORM_RESPONSES", parsedResponses)
          commit("SET_ACTIVE_FORM", parsedResponses[0])
          commit("SET_LOADING", false)
          resolve(parsedResponses)
        })
        .catch((err) => {
          commit("SET_RAW_FORM_RESPONSES", [])
          commit("SET_FORM_RESPONSES", [])
          commit("SET_LOADING", false)
          console.error("Error retrieving form responses", err)
          reject([])
        })
    })
  },
  loadTypeformForms({ commit }, payload) {
    commit("SET_LOADING_TYPEFORM_FORMS", true)
    return axios
      .get("snowflakes/get_forms", {
        params: { integration_use_case: Providers.TYPEFORM },
      })
      .then((resp) => {
        commit("SET_FORMS", resp.data)
      })
      .catch((err) => {
        console.error("Error retrieving form responses: ", err)
      })
      .finally(() => {
        commit("SET_LOADING_TYPEFORM_FORMS", false)
      })
  },
  loadImportedForms: ({ commit, dispatch, getters, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      const searchObj = SearchMixin.buildSearchObject({
        admin: false,
        indices: ["integration_forms"],
        includes: [
          "form_name",
          "response_count_total",
          "response_count_unique",
          "last_response",
          "is_active",
          "actions",
          "external_form_path",
          "external_id",
        ],
        sorts: [{ attr: "last_response", order: "desc" }],
        filters: [
          {
            attr: "access_policy",
            type: "terms",
            value: SearchMixin.buildAccessPolicyWords({
              groups: [
                { id: rootGetters["auth/activeGroup"].id, action: "read" },
              ],
            }),
          },
          {
            attr: "relate_companies",
            type: "term",
            value: true,
          },
        ],
        keywords: payload.q
          ? SearchMixin.keywordsObject(payload.q, [
              { filter: "integration_forms" },
            ])
          : null,
        page: 1,
        per_page: 50,
      })

      axios
        .get("search", { params: { q: searchObj } })
        .then((resp) => {
          const results = _.get(resp, "data.results", [])
          commit("SET_IMPORTED_FORMS", results)
          resolve(results)
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  setEditedFormResponse({ commit, dispatch, getters }, newAnswer) {
    const editedFormFields = setEditedFormResponse(
      getters.editedForm,
      newAnswer
    )
    commit("CACHE_EDITED_FORM_FIELDS", editedFormFields)
  },
  saveEditedFormResponse({ commit, dispatch, getters }) {
    const editedFormResponse = getters.editedForm
    const rawForm = setFormResponses(
      editedFormResponse,
      getters.rawFormResponses
    )

    return new Promise(function (resolve, reject) {
      axios
        .put(`/form_responses/${rawForm.id}`, rawForm)
        .then((res) => {
          commit("UPDATE_CACHED_FORM_RESPONSE", editedFormResponse)
          commit("SET_ACTIVE_FORM", editedFormResponse)
          commit("INCREMENT_ACTIVE_FORM_RELOAD")

          resolve(res)
        })
        .catch((e) => {
          console.error("Error saving form response", e)
          reject(e)
        })
    })
  },
  removeFormRelation({ commit, dispatch, getters }, payload) {
    axios
      .get("form_relations/find", {
        params: {
          q: JSON.stringify({
            find_by: {
              integration_form_id: payload.integration_form_id,
              parent_type: payload.parent.model_type,
              parent_id: payload.parent.id,
            },
          }),
        },
      })
      .then((response) => {
        axios
          .delete(
            `form_relations/${_.get(response, "data.form_relation.id", null)}`
          )
          .then(() => {
            router.push({
              name: "report-view",
              params: { id: payload.parent.id },
            })
          })
          .catch((e) => {
            console.error("Error deleting form relation", e)
          })
      })
  },
  setCanEditFormResponses({ commit }, newCanEditState) {
    commit("SET_CAN_EDIT_FORM_RESPONSES", newCanEditState)
  },
  openTypeformFileUpload({ commit, dispatch, getters }, payload) {
    // encode URI to account special characters in file names. Typeform follows this same pattern.
    payload.url = encodeURI(payload.url)

    axios
      .get("snowflakes/integration_files", {
        params: payload,
        responseType: "blob",
      })
      .then((response) => {
        const link = document.createElement("a")
        link.href = URL.createObjectURL(response.data)
        link.download = getTypeformFileNameFromUrl(decodeURI(payload.url))
        link.click()

        // let the browser know not to keep the reference to the file
        URL.revokeObjectURL(link.href)
      })
      .catch((error) => {
        dispatch(
          "app/setSnackbar",
          {
            text: "An error occurred while trying to download this file. Please contact support@nucla.com for assistance.",
            icon: "warning",
            iconColor: "#DE7974",
          },
          { root: true }
        )
        console.error(error)
      })
  },
  setSelectedTypeform({ commit }, selectedForm) {
    commit("SET_SELECTED_TYPEFORM", selectedForm)
  },

  // ---------
  // Form Table
  // ---------

  setIntegrationForms({ commit }, iForms) {
    commit("SET_INTEGRATION_FORMS", iForms)
  },
  addIntegrationForm({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/snowflakes/integration_form", payload)
        .then((res) => {
          const connection = _.first(_.get(res, "data.connection_object"))
          if (connection) {
            dispatch("connections/pushNewConnection", connection, {
              root: true,
            })
          }
          resolve(_.get(res, "data.integration_form"))
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  refreshForm({ commit }, iForm) {
    commit("SET_LOADING", true)
    return axios
      .put(`integration_forms/${iForm.id}`, {
        last_data_refresh: new Date(Date.now()),
      })
      .then((resp) => {
        commit("SET_INTEGRATION_FORM", resp.data.integration_form)
        commit("SET_LOADING", false)
      })
      .catch((err) => {
        commit("SET_LOADING", false)
        console.error("Error refreshing integration form", iForm.id, err)
      })
  },
  viewForm({ commit }, form) {
    const externalFormPath = _.get(form, "external_form_path")

    if (!_.isEmpty(externalFormPath)) {
      // Link to the external Typeform path, if it exists.
      return window.open(externalFormPath, "_blank", "noopener noreferrer")
    } else {
      // Fallback to Typeform admin settings.
      const path = getProviderPath(
        Providers.TYPEFORM,
        form.external_id || form.id
      )
      return window.open(path, "_blank", "noopener noreferrer")
    }
  },
  viewResponses({ dispatch }, iForm) {
    dispatch(
      "connections/transitionFromWord",
      { word: `if${_.get(iForm, "id")}` },
      {
        root: true,
      }
    )
  },
  resetState({ commit }) {
    commit("RESET_STATE")
  },
}

export const mutations = {
  ...BaseMutations,

  // ---
  // Provider Connections
  // ---

  SET_INTEGRATION(state, data) {
    state.integrations = data
  },
  SET_INTEGRATION_FORM(state, iForm) {
    state.integrationForms = _.map(state.integrationForms, (f) => {
      if (f.id === iForm.id) {
        f = iForm
      }
      return f
    })
  },
  SET_INTEGRATION_FORMS(state, data) {
    state.integrationForms = data
  },
  SET_ERRORS_INTEGRATION(state, data) {
    state.errors.push(data)
  },

  // --
  // Form Integration
  // ---

  SET_TYPEFORM_INTEGRATION(state, typeformIntegration) {
    state.typeformIntegration = typeformIntegration
  },
  SET_FORM_RESPONSES(state, resp) {
    state.formResponses = resp
  },
  SET_RAW_FORM_RESPONSES(state, resp) {
    state.rawFormResponses = resp
  },
  SET_FORMS(state, resp) {
    state.typeformForms = _.get(resp, "items", [])
  },
  SET_LOADING_TYPEFORM_FORMS(state, value) {
    state.loadingTypeformForms = value
  },
  SET_ACTIVE_FORM(state, form) {
    state.activeForm = form
  },
  SET_ACTIVE_FORM_EXTERNAL_PATH(state, form) {
    state.activeFormExternalPath = _.get(form, "_links.display", null)
  },
  SET_EDITED_FORM(state, form) {
    state.editedForm = form
  },
  CACHE_EDITED_FORM_FIELDS(state, editedFields) {
    state.editedForm.fields = editedFields
  },
  UPDATE_CACHED_FORM_RESPONSE(state, updatedFormResponse) {
    const index = _.findIndex(state.formResponses, {
      id: updatedFormResponse.id,
    })
    state.formResponses.splice(index, 1, updatedFormResponse)
    state.activeFormResponse = updatedFormResponse
    state.editedFormResponse = updatedFormResponse
  },
  INCREMENT_ACTIVE_FORM_RELOAD(state) {
    state.activeFormReload += 1
  },
  SET_CAN_EDIT_FORM_RESPONSES(state, newCanEditState) {
    state.canEditFormResponses = newCanEditState || false
  },
  SET_SELECTED_TYPEFORM(state, selectedTypeform) {
    state.selectedTypeform = selectedTypeform
  },

  SET_IMPORTED_FORMS(state, forms) {
    state.importedForms = forms
  },
  RESET_STATE(state) {
    state.integrations = null
    state.typeformIntegration = null
    state.selectedTypeform = null
    state.importedForms = null
    state.typeformForms = []
    state.integrationForms = []
    state.loadingTypeformForms = false
    state.formResponses = []
    state.rawFormResponses = []
    state.activeForm = {}
    state.editedForm = {}
    state.activeFormExternalPath = null
    state.activeFormReload = 0
    state.canEditFormResponses = false
  },
}

// ---
// Private helpers
// ---

function getResponses(obj) {
  return getTypeformResponses(obj)
}

function setFormResponses(editedForm, rawResponses) {
  return setTypeformResponses(editedForm, rawResponses)
}

function setEditedFormResponse(editedForm, newAnswer) {
  return setEditedTypeformResponse(editedForm, newAnswer)
}
