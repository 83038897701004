export const state = {
  params: null,
  actionsOpen: false,
}

export const getters = {
  params: (state) => {
    return _.get(state, "params")
  },
  actionsOpen: (state) => {
    return _.get(state, "actionsOpen")
  },
}

export const actions = {
  reset: ({ commit, dispatch }) => {
    dispatch("app/setActiveDrawer", null, { root: true })
    commit("RESET_STATE")
  },
  setParams: ({ commit, dispatch }, params) => {
    if (params) {
      dispatch("app/setActiveDialog", { dialog: "activeModal" }, { root: true })
    }
    commit("SET_PARAMS", params)
    // Delay to ensure drawer opens when other drawers are active.
    setTimeout(() => {
      dispatch("app/setActiveDrawer", "NotificationsDrawerWrapper", {
        root: true,
      })
    }, 1)
  },
  setActionsOpen: ({ commit }, state) => {
    commit("SET_ACTIONS_OPEN", state)
  },
}

export const mutations = {
  SET_PARAMS(state, params) {
    state.params = params
  },
  SET_ACTIONS_OPEN(state, actionsOpen) {
    state.actionsOpen = actionsOpen
  },
  RESET_STATE(state) {
    state.params = null
    state.actionsOpen = false
  },
}
