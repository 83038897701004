import axios from "axios"

import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"

export const state = {
  ...BaseState,
  createReloadKey: 0,
}

export const getters = {
  ...BaseGetters,
  createReloadKey(state) {
    return _.get(state, "createReloadKey")
  },
}

export const actions = {
  ...BaseActions,
  show: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    const findBy = {
      find_by: {
        resource_one_id: _.get(payload, "params.resource_one_id"),
        resource_one_type: _.get(payload, "params.resource_one_type"),
        group_id: _.get(payload, "params.group_id"),
        use_case: _.get(payload, "params.use_case"),
      },
    }

    return axios
      .get("share_tokens/find", {
        params: {
          q: JSON.stringify(findBy),
        },
      })
      .then((response) => {
        commit("SET_MODEL", response.data[payload.model])
        commit("SET_INCLUDE", response.data)
        return response.data[payload.model]
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  update: ({ commit }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")
    const findBy = {
      find_by: {
        resource_one_id: payload.params.resource_one_id,
        resource_one_type: payload.params.resource_one_type,
        group_id: payload.params.group_id,
        use_case: payload.params.use_case,
      },
    }

    return axios
      .put("share_tokens/find", {
        q: JSON.stringify(findBy),
        share_token: payload.params,
      })
      .then((response) => {
        commit("SET_MODEL", response.data[payload.model])
        commit("SET_INCLUDE", response.data)
        return response.data[payload.model]
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },
  delete: ({ commit, dispatch }, payload) => {
    commit("SET_LOADING", true)
    commit("CLEAR_ERRORS")

    const findBy = {
      find_by: {
        resource_one_id: payload.params.resource_one_id,
        resource_one_type: payload.params.resource_one_type,
        group_id: payload.params.group_id,
        use_case: payload.params.use_case,
      },
    }

    axios
      .delete("share_tokens/find", {
        params: {
          q: JSON.stringify(findBy),
        },
      })
      .then(() => {
        commit("SET_MODEL", null)
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
      .finally(() => {
        commit("SET_LOADING", false)
        commit("INCREMENT_RELOAD_KEY")
        dispatch("reports/reloadReport", {}, { root: true })
      })
  },
  progressCreateReloadKey({ commit }) {
    commit("PROGRESS_CREATE_RELOAD_KEY")
  },
}

export const mutations = {
  ...BaseMutations,
  PROGRESS_CREATE_RELOAD_KEY() {
    state.createReloadKey++
  },
}
