import axios from "axios"
import Vue from "vue"
import { ConnectionUseCase } from "@mixins/connections"
import { DataSources } from "@mixins/data-sources"

export const state = {
  groupFieldCards: [],
  groupGfc: {},
  indexedFieldChanges: {},
  dataLimitReached: false,
  loading: false,
}

export const getters = {
  loading: (state) => {
    return state.loading
  },
  groupFieldCards: (state) => {
    return state.groupFieldCards
  },
  groupGfcFields: (state) => {
    return _.get(state, "groupGfc.value.fields") || []
  },
  indexedFieldsDowncaseTimeout: (state) => {
    return _.get(state, "groupGfc.value.last_indexed_fields_downcase")
  },
  indexedFieldChanges: (state) => {
    return state.indexedFieldChanges
  },
  originalFieldHidMapping: (state, getters) => {
    let fieldHidMapping = {}
    for (var i = 0; i < getters.groupGfcFields.length; i++) {
      fieldHidMapping[getters.groupGfcFields[i].hid] =
        getters.groupGfcFields[i].indexed
    }
    return fieldHidMapping
  },
  dataLimitReached: (state) => {
    return state.dataLimitReached
  },

  // INDEXED FIELD COUNTS
  activatedFieldCount: (state, getters) => {
    return getters.groupGfcFields.map((x) => x["indexed"]).filter(Boolean)
      .length
  },
  fieldsToActivateCount: (state) => {
    return _.filter(Object.values(state.indexedFieldChanges), function (o) {
      return o["indexed"]
    }).length
  },
  fieldsToDeactivateCount: (state) => {
    return _.filter(Object.values(state.indexedFieldChanges), function (o) {
      return !o["indexed"]
    }).length
  },
}

export const actions = {
  // ===================
  // TAXONOMY MANAGEMENT
  // ===================
  newTaxonomy: ({ commit }, taxonomy) => {
    commit("NEW_STORE_TAXONOMY_STATE", taxonomy)
  },
  updatedTaxonomy: ({ commit }, taxonomy) => {
    commit("UPDATE_STORE_TAXONOMY_STATE", taxonomy)
  },
  deletedTaxonomy: ({ commit, dispatch }, taxonomy) => {
    commit("DELETE_STORE_TAXONOMY_STATE", taxonomy)
  },
  updateTaxonomyFieldOrder: ({ commit }, taxonomyUpdates) => {
    return new Promise((resolve) => {
      commit("UPDATE_STORE_TAXONOMY_FIELD_ORDER_STATE", taxonomyUpdates)
      resolve()
    })
  },
  // ===================
  // FIELD MANAGEMENT
  // ===================
  newField: ({ commit }, field) => {
    commit("NEW_STORE_FIELD_STATE", field)
  },
  updatedField: ({ commit }, field) => {
    commit("UPDATE_STORE_FIELD_STATE", field)
  },
  deletedField: ({ commit }, field) => {
    commit("DELETE_STORE_FIELD_STATE", field)
  },
  // ===================
  // INDEXED FIELDS MANAGEMENT
  // ===================
  indexedFieldChange: ({ commit, getters }, gfcField) => {
    const fieldIsIndexed = getters.originalFieldHidMapping[gfcField["hid"]]
    if (
      (fieldIsIndexed && !gfcField["indexed"]) ||
      (!fieldIsIndexed && gfcField["indexed"])
    ) {
      commit("ADD_INDEXED_FIELD_CHANGE", gfcField)
    } else {
      commit("REMOVE_INDEXED_FIELD_CHANGE", gfcField)
    }
  },
  updateIndexedFields: ({ commit, getters, dispatch }, params) => {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      axios
        .put("snowflakes/change_indexed_fields", params)
        .then((res) => {
          const indexedFields = _.get(res, "data.indexed_fields") || []
          if (getters.fieldsToDeactivateCount) {
            commit("SET_LAST_INDEXED_FIELDS_DOWNCASE", new Date())
          }
          commit("UPDATE_STORE_FIELD_INDEXED_STATES", indexedFields)
          resolve()
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
        .finally(() => {
          // set filter card templates to null to fetch new data when the filter
          // card grid is reopened
          dispatch(
            "filterCardGrid/set",
            {
              k: "filterCardTemplate",
              v: null,
            },
            { root: true }
          )
          commit("SET_LOADING", false)
        })
    })
  },
  activateCardFields: ({ commit, getters, dispatch }, params) => {
    let fields = params.fields
    let failedActivation = 0
    for (var i = 0; i < fields.length; i++) {
      let dataLimit =
        getters.activatedFieldCount -
        getters.fieldsToDeactivateCount +
        getters.fieldsToActivateCount
      if (params.dataTier === dataLimit) {
        failedActivation += 1
        continue
      }
      const gfcField = _.cloneDeep(fields[i])
      gfcField.indexed = true
      dispatch("indexedFieldChange", gfcField)
    }

    if (failedActivation) {
      dispatch(
        "app/setSnackbar",
        {
          text: `Failed to activate ${failedActivation} field${
            failedActivation > 1 ? "s" : ""
          }. Ensure there are enough available fields for this operation.`,
          icon: "warning",
          iconColor: "#DE7974",
        },
        { root: true }
      )
    }
  },
  deactivateCardFields: ({ commit, getters, dispatch }, params) => {
    let fields = params.fields
    for (var i = 0; i < fields.length; i++) {
      const gfcField = _.cloneDeep(fields[i])
      gfcField.indexed = false
      dispatch("indexedFieldChange", gfcField)
    }
  },
  resetCardFields: ({ commit, getters, dispatch }, params) => {
    let fields = params.fields
    let failedResets = 0
    for (var i = 0; i < fields.length; i++) {
      const gfcField = _.cloneDeep(fields[i])
      let dataLimit =
        getters.activatedFieldCount -
        getters.fieldsToDeactivateCount +
        getters.fieldsToActivateCount

      let indexedValue = _.get(
        getters.indexedFieldChanges,
        `${gfcField.hid}.indexed`
      )
      if (indexedValue === false && params.dataTier === dataLimit) {
        failedResets += 1
        continue
      }

      commit("REMOVE_INDEXED_FIELD_CHANGE", gfcField)
    }

    if (failedResets) {
      dispatch(
        "app/setSnackbar",
        {
          text: `Failed to reset ${failedResets} field${
            failedResets > 1 ? "s" : ""
          }. Ensure there are enough available fields for this operation.`,
          icon: "warning",
          iconColor: "#DE7974",
        },
        { root: true }
      )
    }
  },
  resetAllCardFields: ({ commit }) => {
    commit("RESET_INDEXED_FIELD_CHANGES")
  },
  loadAllFieldCardsWithData: ({ commit, dispatch }, params) => {
    // AllFieldCards Snowflake API
    // https://stage.api.nucla.com/api/v1/snowflakes/all_field_cards?group_id=1
    return new Promise((resolve, reject) => {
      axios
        .get("snowflakes/all_field_cards", {
          params: params,
        })
        .then((response) => {
          const cards = _.get(response, "data.cards")
          commit("SET_GROUP_FIELD_CARDS", cards)
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  fetchGroupGfcFields: ({ commit }, params) => {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/cache?id=group_fields_caches&cache[group_id]=${params.group_id}&cache[permission_report_id]=${params.report_id}`
        )
        .then((response) => {
          const gfc = _.get(response, "data") || {}
          const fields = _.get(gfc, "value.fields")

          Vue.set(gfc.value, "fields", fields ? JSON.parse(fields) : [])

          commit("SET_GROUP_GFC", gfc)
          resolve()
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
  setDataLimitReached: ({ commit }, boolean) => {
    commit("SET_DATA_LIMIT_REACHED", boolean)
  },
  resetState: ({ commit }) => {
    commit("RESET_STATE")
  },
}

export const mutations = {
  SET_LOADING: (state, boolean) => {
    state.loading = boolean
  },
  RESET_STATE: (state) => {
    state.groupFieldCards = []
    state.groupGfc = {}
    state.indexedFieldChanges = {}
    state.dataLimitReached = false
    state.loading = false
  },
  SET_GROUP_FIELD_CARDS: (state, cards) => {
    state.groupFieldCards = cards
  },
  SET_GROUP_GFC: (state, gfc) => {
    state.groupGfc = gfc
  },
  ADD_INDEXED_FIELD_CHANGE: (state, gfcField) => {
    Vue.set(state.indexedFieldChanges, gfcField["hid"], gfcField)
  },
  REMOVE_INDEXED_FIELD_CHANGE: (state, gfcField) => {
    Vue.delete(state.indexedFieldChanges, gfcField["hid"])
  },
  RESET_INDEXED_FIELD_CHANGES: (state) => {
    Vue.set(state, "indexedFieldChanges", {})
  },
  SET_DATA_LIMIT_REACHED: (state, boolean) => {
    state.dataLimitReached = boolean
  },
  // ===================
  // TAXONOMY MANAGEMENT
  // ===================
  NEW_STORE_TAXONOMY_STATE: (state, taxonomy) => {
    state.groupFieldCards.unshift(taxonomy)

    if (taxonomy.default_taxonomy) {
      const fieldCards = state.groupFieldCards
      for (var i = 0; i < fieldCards.length; i++) {
        if (fieldCards[i].use_case !== ConnectionUseCase.GROUP_ACTIVE) continue
        fieldCards[i].default_taxonomy =
          taxonomy.taxonomy_id === fieldCards[i].taxonomy_id
      }
    }
  },
  UPDATE_STORE_TAXONOMY_STATE: (state, taxonomy) => {
    let taxonomyCard = _.find(state.groupFieldCards, function (card) {
      return (
        card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        card.taxonomy_id === taxonomy.id
      )
    })
    taxonomyCard.name = taxonomy.label

    if (taxonomy.default && !taxonomyCard.default_taxonomy) {
      const fieldCards = state.groupFieldCards
      for (var i = 0; i < fieldCards.length; i++) {
        if (fieldCards[i].use_case !== ConnectionUseCase.GROUP_ACTIVE) continue
        fieldCards[i].default_taxonomy =
          taxonomy.id === fieldCards[i].taxonomy_id
      }
    }
  },
  DELETE_STORE_TAXONOMY_STATE: (state, taxonomy) => {
    const deletedTaxonomy = _.find(state.groupFieldCards, function (card) {
      return (
        card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        card.taxonomy_id === taxonomy.id
      )
    })
    const defaultTaxonomy = _.find(state.groupFieldCards, function (card) {
      return (
        card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        card.default_taxonomy
      )
    })
    defaultTaxonomy.fields.push(...deletedTaxonomy.fields)

    state.groupFieldCards = _.reject(state.groupFieldCards, function (card) {
      return (
        card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        card.taxonomy_id === taxonomy.id
      )
    })
  },
  UPDATE_STORE_TAXONOMY_FIELD_ORDER_STATE: (state, taxonomyUpdates) => {
    for (var i = 0; i < taxonomyUpdates.length; i++) {
      let taxonomyCard = _.find(state.groupFieldCards, function (card) {
        return (
          card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
          card.taxonomy_id === taxonomyUpdates[i]["id"]
        )
      })
      taxonomyCard["fields"] = taxonomyUpdates[i]["fields"]
    }
  },
  // ===================
  // FIELD MANAGEMENT
  // ===================
  NEW_STORE_FIELD_STATE: (state, field) => {
    // if gfc field structure changes, then this could break shit at some point
    const psuedoGfcField = {
      attribute: `field_${field.hid}`,
      category: null,
      hid: field.hid,
      id: field.id,
      indexed: !state.dataLimitReached,
      label: field.label,
      source: DataSources.GROUP,
      source_id: field.group_id,
      // source_word: source word is added below
      taxonomy_id: field.taxonomy_id,
      use_case: field.use_case,
    }

    // Add to settings taxonomy card
    const taxonomy = _.find(state.groupFieldCards, function (card) {
      return (
        card.use_case === ConnectionUseCase.GROUP_ACTIVE &&
        card.taxonomy_id === field.taxonomy_id
      )
    })
    // Add source word to psuedoGfcField
    psuedoGfcField["source_word"] = taxonomy["word"]
    // Add field to taxonomy
    taxonomy["fields"].push(psuedoGfcField)

    // Add too settings GFC collection
    const gfcFields = _.get(state, "groupGfc.value.fields") || []
    gfcFields.push(psuedoGfcField)
  },
  UPDATE_STORE_FIELD_STATE: (state, field) => {
    const fieldTaxId = field.taxonomy_id
    const gfcFields = _.get(state, "groupGfc.value.fields") || []
    const gfcField = _.find(gfcFields, function (f) {
      return f.hid === field.hid
    })
    gfcField.label = field.label
    gfcField.taxonomy_id = field.taxonomy_id

    const fieldCards = _.filter(state.groupFieldCards, function (card) {
      return card.use_case === ConnectionUseCase.GROUP_ACTIVE
    })
    for (var i = 0; i < fieldCards.length; i++) {
      let cardField = _.find(fieldCards[i]["fields"], function (f) {
        return f.hid === field.hid
      })

      if (fieldTaxId === fieldCards[i].taxonomy_id) {
        // if card field exists within state, then we know that the field's taxonomy wasn't updated
        if (cardField) {
          cardField.label = field.label
          break
        } else {
          // taxonomy updated, push gfcField into card
          fieldCards[i]["fields"].push(gfcField)
        }
      } else if (fieldTaxId !== fieldCards[i].taxonomy_id && cardField) {
        // remove field from previous taxonomy
        fieldCards[i]["fields"] = _.filter(
          fieldCards[i]["fields"],
          function (f) {
            return f.hid !== field.hid
          }
        )
      }
    }
  },
  DELETE_STORE_FIELD_STATE: (state, field) => {
    const gfcFields = _.get(state, "groupGfc.value.fields") || []
    const gfcFieldIndex = _.findIndex(gfcFields, function (f) {
      return f.id === field.id
    })
    if (gfcFieldIndex >= 0) gfcFields.splice(gfcFieldIndex, 1)

    const fieldCards = _.filter(state.groupFieldCards, function (card) {
      return card.use_case === ConnectionUseCase.GROUP_ACTIVE
    })

    for (var i = 0; i < fieldCards.length; i++) {
      let cardFieldIndex = _.findIndex(fieldCards[i]["fields"], function (f) {
        return f.id === field.id
      })
      if (cardFieldIndex === -1) continue

      fieldCards[i]["fields"].splice(cardFieldIndex, 1)
      break
    }
  },
  UPDATE_STORE_FIELD_INDEXED_STATES: (state, indexedFields) => {
    const gfcFields = _.get(state, "groupGfc.value.fields") || []
    const ktFields = [DataSources.KITE, DataSources.SYSTEM]

    for (var i = 0; i < gfcFields.length; i++) {
      Vue.set(
        gfcFields[i],
        "indexed",
        indexedFields.includes(gfcFields[i]["hid"]) ||
          ktFields.includes(gfcFields[i]["source"])
      )
    }

    const fieldCards = state.groupFieldCards
    for (var i = 0; i < fieldCards.length; i++) {
      for (var j = 0; j < fieldCards[i]["fields"].length; j++) {
        Vue.set(
          fieldCards[i]["fields"][j],
          "indexed",
          indexedFields.includes(fieldCards[i]["fields"][j]["hid"])
        )
      }
    }

    state.indexedFieldChanges = {}
  },
  SET_LAST_INDEXED_FIELDS_DOWNCASE: (state, time) => {
    Vue.set(state, "groupGfc.value.last_indexed_fields_downcase", time)
  },
}
