import {
  BaseState,
  BaseActions,
  BaseMutations,
  BaseGetters,
} from "@utils/mixins/store"
import axios from "axios"

export const state = {
  ...BaseState,
}

export const getters = {
  ...BaseGetters,
}

export const actions = {
  ...BaseActions,
  fetchReportPosts: ({ commit }, payload) => {
    return axios
      .get("snowflakes/report_posts", {
        params: { report_id: payload.report_id },
      })
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        commit("SET_ERRORS", e)
      })
  },
  setNewPost: ({ commit }, newPost) => {
    commit("SET_MODEL", newPost)
  },
  delete: ({ dispatch, commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", true)
      commit("CLEAR_ERRORS")

      axios
        .delete("posts/" + payload.id)
        .then(() => {
          commit("SET_LOADING", false)
          commit("SET_MODEL", null)
          setTimeout(() => {
            dispatch("incrementReloadKey", null)
          }, 500)
          resolve()
        })
        .catch((e) => {
          commit("SET_ERRORS", e)
          reject(e)
        })
        .finally(() => {
          commit("SET_LOADING", false)
        })
    })
  },
}

export const mutations = {
  ...BaseMutations,
}
